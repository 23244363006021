import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { toast } from "react-toastify";
import { FormValidation, FormValidationOnChange } from "./changePassValidation";
import { changePassword } from "../services/services";
import { BiSolidHide,BiShow } from "react-icons/bi";

const buttonWidth = {
  width: "100%",
};

const ChangePassword = (props) => {
  const { show, handleClose } = props;
  const [inputField, setInputField] = useState({
    password: "",
    confirmPassword: "",
  });
  const [errField, setErrField] = useState({
    passwordErr: "",
    confirmPasswordErr: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const togglePasswordVisibility = () => {
      setShowPassword(!showPassword);
  };
  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
};

  const handleSubmit = (e) => {
    e.preventDefault();
    if (FormValidation({ inputField, setErrField })) {
      if (inputField?.password.trim() !== inputField.confirmPassword.trim()) {
        return toast.error("Password and confirmed password do not match");
      }
      console.log(inputField.password);
      let data = {
        password: inputField.password,
      };
      changePassword(data).then((res) => {
        if (res.status === 200) {
            handleClose()
          toast.success(res.data.message);
        }
      });
    }
  };

  const handleChangeCode = (e) => {
    setInputField({ ...inputField, [e.target.name]: e.target.value });
    let inputName = e.target.name;
    let inputValue = e?.target?.value;
    FormValidationOnChange({ inputValue, setErrField, inputName });
  };

  return (
    <Modal show={show} onHide={handleClose} centered size="md">
      <Modal.Header closeButton={true} className="border-0 mt-4 pt-0 pb-2">
        <div className="d-flex justify-content-center w-100">
          <Modal.Title className="h5">Change Password</Modal.Title>
        </div>
      </Modal.Header>
      <Modal.Body className="p-4">
        <form onSubmit={handleSubmit} className="form-login n">
          <div className="mb-4 position-relative">
            <label className="form-label">New Password</label>
            <input
              type={showPassword ? "text" : "password"}
              className="form-control py-3"
              placeholder="Enter New Password"
              name="password"
              value={inputField?.password}
              onChange={handleChangeCode}
              maxLength={15} // Ensure the input field does not accept more than 8 characters
            />
            <div className='password-hide-show-NEW' onClick={togglePasswordVisibility}>{showPassword?<BiShow />:<BiSolidHide />} </div>
            {/* {inputError && <div className="text-danger mt-2">{inputError}</div>} */}
            {errField?.passwordErr?.length > 0 && (
              <div className="text-danger mt-2">{errField?.passwordErr}</div>
            )}
          </div>
          <div className="mb-4 position-relative">
            <label className="form-label">Confirm Password</label>
            <input
              type={showConfirmPassword ? "text" : "password"}
              className="form-control py-3"
              placeholder="Enter Confirm Password"
              name="confirmPassword"
              value={inputField?.confirmPassword}
              onChange={handleChangeCode}
              maxLength={15} // Ensure the input field does not accept more than 8 characters
            />
           <div className='password-hide-show-CONFIRM' onClick={toggleConfirmPasswordVisibility}>{showConfirmPassword?<BiShow />:<BiSolidHide />} </div>
            {/* {inputError && <div className="text-danger mt-2">{inputError}</div>} */}
            {errField?.confirmPasswordErr?.length > 0 && (
              <div className="text-danger mt-2">
                {errField?.confirmPasswordErr}
              </div>
            )}
          </div>
          <div className="text-center">
            <button
              type="submit"
              className="btn btn-invite next fw-bolder update-class"
              style={buttonWidth}
            >
              Update Password
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default ChangePassword;
