import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import Login from "../components/login";
import ForgetPassword from "../components/forget-password";
import Home from "../components/home";
import ChangePassword from "../components/reset-password";
import Dashboard from "../components/dashboard";
import Users from "../components/Users/users";
import UserDetails from "../components/Users/userDetails";
import ApprovedModels from "../components/Models/approvedModels";
import UnApprovedModels from "../components/Models/unApprovedModel";
import ModelDetails from "../components/Models/modelDetails";
import RejectedModels from "../components/Models/rejectedModels";
import CountryRegion from "../components/country-region";
import OtpVerification from "../components/otpVerification";
import ResetPassword from "../components/reset-password";
import Faq from "../components/Faqs/addFaq";
import FaqListing from "../components/Faqs/faqList";
import EditFaq from "../components/Faqs/editFaq";
import AboutUs from "../components/aboutus";
import TermsCondition from "../components/terms&condition";
import PrivacyPolicy from "../components/privacyPolicy";
import TermsAndCondition from "../components/StaticWebUrls/termsAndConditions";
import PrivacyAndPolicy from "../components/StaticWebUrls/privacyPolicy";
import ModelTransaction from "../components/transactions/ModelTransaction";
import useAdminContext from "../context/appContext";
import { useEffect } from "react";
import BankDetails from "../components/bankDetails/BankDetails";
import CryptoPayment from "../components/cryptoPayment/CryptoPayment";
import ViewCryptoPayout from "../components/cryptoPayment/ViewCryptoPayout";
import UserSupport from "../components/userSupport/UserSupport";
import InvitationCode from "../components/invitationCode/InvitationCode";
import ViewBanksDetails from "../components/bankDetails/ViewBanksDetails";
import SubAdminManagement from "../components/subAdminManagement/SubAdminManagement";
import Report from "../components/report/Report";
import ViewUserSupport from "../components/userSupport/ViewUserSupport";
import Aggrement from "../components/aggrement";

const AppRoutes = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { isLoggedIn, setIsLoggedIn, token, setToken } = useAdminContext();

  const publicRoutes = [
    "/forget-password",
    "/otp",
    "/reset-password",
    "/terms&condition",
    "/privacyPolicy",
  ];

  useEffect(() => {
    // // setToken(null)
    if (!isLoggedIn) {
      console.log("localstrogae token not equal");
      localStorage.clear();
      if (!publicRoutes.includes(location.pathname)) {
        navigate("/");
      }
    }
  }, [navigate, isLoggedIn]);

  const tokenRequired = () => {
    if (publicRoutes.includes(location.pathname)) {
      return false;
    } else {
      if (isLoggedIn) {
        return true;
      } else {
        return false;
      }
    }
  };
  return (
    <Routes>
      {tokenRequired() ? (
        <>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/sub-admin" element={<SubAdminManagement />} />
          <Route path="/users" element={<Users />} />
          <Route path="/models" element={<ApprovedModels />} />
          <Route path="/unappoved-models" element={<UnApprovedModels />} />
          <Route path="/rejected-models" element={<RejectedModels />} />
          <Route path="/userDetails/:id" element={<UserDetails />} />
          <Route path="/modelDetails/:id" element={<ModelDetails />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/region-country" element={<CountryRegion />} />
          <Route path="/manage-usersupport" element={<UserSupport />} />
          <Route path="/manage-usersupport-detail/:id" element={<ViewUserSupport />} />
          <Route path="/add-faq" element={<Faq />} />
          <Route path="/faqs" element={<FaqListing />} />
          <Route path="/edit-faq/:id" element={<EditFaq />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/terms-condition" element={<TermsCondition />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/agrement-contract" element={<Aggrement />} />
          <Route path="/model-withdrawls" element={<ModelTransaction />} />
          <Route path="/bank-details" element={<BankDetails />} />
          <Route path="/crypto-payout" element={<CryptoPayment />} />
  
          <Route path="/cryptoPayoutDetail/:id" element={<ViewCryptoPayout />} />
          <Route path="/bank-detail/:id" element={<ViewBanksDetails />} />
          <Route path="/invitation-code" element={<InvitationCode />} />
          <Route path="/report" element={<Report />} />
          
          <Route path="/" element={<Dashboard />} />
        </>
      ) : (
        <>
          <Route path="/" element={<Login />} />
          <Route path="/forget-password" element={<ForgetPassword />} />
          <Route path="/otp" element={<OtpVerification />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/terms&condition" element={<TermsAndCondition />} />
          <Route path="/privacyPolicy" element={<PrivacyAndPolicy />} />
          <Route path="*" element={<Login />} />
        </>
      )}
    </Routes>
  );
};

export default AppRoutes;
